import React, { useState, useEffect, useContext } from "react";
import ReactPaginate from "react-paginate";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";
import TextField from "@mui/material/TextField";
import Navbar from "../components/common/Navbar";
import categoryData from "../api/category";
import CategoryTable from "../components/category-table/CategoryTable";
import Loading from "../components/material-ui/Loading";
import { AuthContext } from "../context/Auth/AuthContext";
import AddCategory from "../components/category-table/AddCategory";
import "../styles/Category.css";

const Category = () => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [openFilter, setOpenFilter] = useState(false);
  const [categoryResponse, setCategoryResponse] = useState([]);
  const [categoryAdded, setCategoryAdded] = useState(0);
  const [categoryUpdated, setCategoryUpdated] = useState(0);
  const [categoryDeleted, setCategoryDeleted] = useState(0);
  const [showAddCategoryForm, setShowAddCategoryForm] = useState(false);

  const [globalSearch, setGlobalSearch] = useState("");

  const [api, setApi] = useState(
    `https://lvdmsup.nablasol.net/DMS/api/get_category?limit=20&offset=0`
  );

  let limit = 20;
  const offset = currentPage * limit;

  let global_search = globalSearch ? `&global_search=${globalSearch}` : "";

  const { token } = useContext(AuthContext);

  const categoryResponseFunc = async () => {
    setLoading(true);
    const categories = await categoryData({ token, api });
    setPageCount(Math.ceil(categories?.allCategoryCount / limit));
    setCategoryResponse(categories.category);
    if (currentPage > Math.ceil(categories?.allCategoryCount / limit)) {
      setCurrentPage(Math.ceil(categories?.allCategoryCount / limit));
    }
    setLoading(false);
  };

  const onAddCategoryButtonClick = () => {
    setShowAddCategoryForm(!showAddCategoryForm);
  };

  useEffect(() => {
    categoryResponseFunc();
  }, [api, categoryAdded, categoryUpdated, categoryDeleted]);

  const onGlobalSearchChange = (e) => {
    setGlobalSearch(e.target.value);
  };

  const setCategoryAddedToTrue = () => {
    setCategoryAdded((count) => count + 1);
  };

  const setCategoryUpdatedToTrue = () => {
    setCategoryUpdated((count) => count + 1);
  };

  const setCategoryDeletedToTrue = (documentList) => {
    setCategoryDeleted((count) => count + 1);
  };

  useEffect(() => {
    setApi(
      `https://lvdmsup.nablasol.net/DMS/api/get_category?limit=${limit}&offset=${offset}${global_search}`
    );
  }, [currentPage]);

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      setApi(
        `https://lvdmsup.nablasol.net/DMS/api/get_category?limit=${20}&offset=${0}${global_search}`
      );
    }, 1000);

    return () => clearTimeout(debounceFn);
  }, [globalSearch]);

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  return (
    <div className="category-container">
      <div className="navbar">
        <Navbar />
      </div>
      <div className="category-table">
        {showAddCategoryForm ? (
          <AddCategory
            onAddCategoryButtonClick={onAddCategoryButtonClick}
            setCategoryAddedToTrue={setCategoryAddedToTrue}
          />
        ) : (
          <div className="category-content">
            <div className="category-table-header">
              <TextField
                name="globalSearch"
                label="Search"
                type="text"
                autoComplete="off"
                value={globalSearch}
                onChange={onGlobalSearchChange}
                variant="outlined"
                InputProps={{
                  endAdornment: <AiOutlineSearch />,
                }}
              />
              <button
                className="add-category"
                onClick={onAddCategoryButtonClick}
              >
                Add Category
              </button>
            </div>

            {!loading ? (
              categoryResponse.length > 0 ? (
                <div className="category-table-content">
                  <CategoryTable
                    loading={loading}
                    setCategoryUpdatedToTrue={setCategoryUpdatedToTrue}
                    setCategoryDeletedToTrue={setCategoryDeletedToTrue}
                    categoryTableData={categoryResponse}
                  />
                  <div className="category-table-pagination">
                    <ReactPaginate
                      previousLabel={<MdOutlineArrowBackIos />}
                      nextLabel={<MdOutlineArrowForwardIos />}
                      pageCount={pageCount}
                      onPageChange={handlePageClick}
                      forcePage={currentPage}
                      containerClassName={"category-pagination"}
                      previousLinkClassName={"category-pagination-link"}
                      nextLinkClassName={"category-pagination-link"}
                      disabledClassName={"category-pagination-link-disabled"}
                      activeClassName={"category-pagination-link-active"}
                    />
                  </div>
                </div>
              ) : (
                <h5 className="no-user-data">No Data Available</h5>
              )
            ) : (
              <Loading />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Category;
