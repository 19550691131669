import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import { FaUserCog } from "react-icons/fa";
import { BiCategory } from "react-icons/bi";
import Loading from "../components/material-ui/Loading";
import Navbar from "../components/common/Navbar";
import PieChart from "../components/charts/PieChart";
import BarChart from "../components/charts/BarChart";
import { AuthContext } from "../context/Auth/AuthContext";
import dashboardData from "../api/dashboard";
import documentChart from "../api/documentChart";
import Calendar from "../components/common/Calendar";
import "../styles/Dashboard.css";

// Document Editor Tool Like MS Office/Libre Office with Create Document, draft, review and approve feature + Collaboration similar to google docs

const Dashboard = () => {
  const [loading, setLoading] = useState(true);

  const [dashboardResponse, setDashboardResponse] = useState({});
  const [documentUploadedResponse, setDocumentUploadedResponse] = useState([]);
  const { token, userID, isAdmin } = useContext(AuthContext);

  const dashboardAPIData = async () => {
    const dashboardResponseFunc = async () => {
      const response = await dashboardData({ token, userID, isAdmin });
      const data = await response?.data?.[0];
      setDashboardResponse(data);
    };

    const documentUploadedResponseFunc = async () => {
      const response = await documentChart({ token, userID, isAdmin });
      const data = await response?.data;
      setDocumentUploadedResponse(data);
    };

    await dashboardResponseFunc();
    await documentUploadedResponseFunc();
    setLoading(false);
  };

  useEffect(() => {
    dashboardAPIData();
  }, []);

  const notify = [
    {
      name: "NBL DMS Report",
      action: "added",
      user: "Meet Mehta",
      date: "01/18/2023",
    },
    {
      name: "NBL DMS Report",
      action: "deleted",
      user: "Meet Mehta",
      date: "01/18/2023",
    },
    {
      name: "NBL DMS Report",
      action: "edited",
      user: "Meet Mehta",
      date: "01/18/2023",
    },
    {
      name: "NBL DMS Report",
      action: "deleted",
      user: "Meet Mehta",
      date: "01/18/2023",
    },
    {
      name: "NBL DMS Report",
      action: "added",
      user: "Meet Mehta",
      date: "01/18/2023",
    },
    {
      name: "NBL DMS Report",
      action: "edited",
      user: "Meet Mehta",
      date: "01/18/2023",
    },
    {
      name: "NBL DMS Report",
      action: "deleted",
      user: "Meet Mehta",
      date: "01/18/2023",
    },
    {
      name: "NBL DMS Report",
      action: "edited",
      user: "Meet Mehta",
      date: "01/18/2023",
    },
  ];

  const action = [
    {
      name: "NBL DMS Report",
      action: "added",
      user: "Meet Mehta",
      day: "7 days",
    },
    {
      name: "NBL DMS Report",
      action: "added",
      user: "Meet Mehta",
      day: "10 days",
    },
    {
      name: "NBL DMS Report",
      action: "added",
      user: "Meet Mehta",
      day: "5 days",
    },
    {
      name: "NBL DMS Report",
      action: "added",
      user: "Meet Mehta",
      day: "12 days",
    },
    {
      name: "NBL DMS Report",
      action: "added",
      user: "Meet Mehta",
      day: "10 days",
    },
    {
      name: "NBL DMS Report",
      action: "added",
      user: "Meet Mehta",
      day: "5 days",
    },
    {
      name: "NBL DMS Report",
      action: "deleted",
      user: "Meet Mehta",
      day: "8 days",
    },
    {
      name: "NBL DMS Report",
      action: "added",
      user: "Meet Mehta",
      day: "5 days",
    },
  ];

  return (
    <div className="dashboard-container">
      <div className="navbar">
        <Navbar />
      </div>
      {!loading ? (
        <div className="dashboard-page-content">
          <div className="dahboard-column">
            <div className="dashboard-data">
              <div className="box-part-one">
                <Link to="/users" className="dashboard-stats">
                  <div className="data-count">
                    {dashboardResponse?.userCount || 0}
                  </div>
                  <div className="data-with-icon">
                    <div>
                      <FaUserCog />
                    </div>
                    <div className="data">Users</div>
                  </div>
                </Link>
                <Link to="/category" className="dashboard-stats">
                  <div className="data-count">
                    {dashboardResponse?.categoryCount}
                  </div>
                  <div className="data-with-icon">
                    <div>
                      <BiCategory />
                    </div>
                    <div className="data">Categories</div>
                  </div>
                </Link>
              </div>
              <div className="box-part-two">
                <Link to="/documents" className="dashboard-stats">
                  <div className="data-count">
                    {dashboardResponse?.documentCount}
                  </div>
                  <div className="data-with-icon">
                    <div>
                      <CgFileDocument />
                    </div>
                    <div className="data">Documents</div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="piechart">
              <PieChart
                logicdocument={dashboardResponse?.logicdocument}
                MMSdocument={dashboardResponse?.MMSdocument}
                Manualdocument={dashboardResponse?.Manualdocument}
                APIdocument={dashboardResponse?.APIdocument}
                Sugardocument={dashboardResponse?.Sugardocument}
              />
            </div>
          </div>
          <div className="dahboard-column">
            <div className="notification-section-one">
              <div className="notification-title">Latest Notifications</div>
              <div className="notification-container">
                {notify.map((notification, index) => (
                  <div className="latest-notification" key={index}>
                    <div
                      style={{
                        color: "#10e232",
                      }}
                    >
                      <CgFileDocument />
                    </div>
                    <div className="notification-details">
                      <span style={{ fontWeight: "600" }}>
                        {notification.name}
                      </span>{" "}
                      <span>{notification.type}</span> has been{" "}
                      <span style={{ fontWeight: "600" }}>
                        {notification.action}
                      </span>{" "}
                      by{" "}
                      <span style={{ fontWeight: "600" }}>
                        {notification.user}
                      </span>{" "}
                      on{" "}
                      <span style={{ fontWeight: "600" }}>
                        {notification.date.substring(0, 10)}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="notification-section-two">
              <div className="notification-title">Action Required</div>
              <div className="notification-container">
                {action.map((notification, index) => (
                  <div className="latest-notification" key={index}>
                    <div
                      style={{
                        color: "#d50c2acc",
                      }}
                    >
                      <CgFileDocument />
                    </div>
                    <div className="notification-details">
                      <span style={{ fontWeight: "600" }}>
                        {notification.name}
                      </span>{" "}
                      <span>{notification.type}</span> has been{" "}
                      <span style={{ fontWeight: "600" }}>
                        {notification.action}
                      </span>{" "}
                      by{" "}
                      <span style={{ fontWeight: "600" }}>
                        {notification.user}
                      </span>{" "}
                      on{" "}
                      <span style={{ fontWeight: "600" }}>
                        {notification.day} before
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="dashboard-column-2">
            <BarChart documentUploadedResponse={documentUploadedResponse} />
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Dashboard;
