import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid, Paper } from "@mui/material";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import AvatarUI from "../components/material-ui/AvatarUI";
import InputUI from "../components/material-ui/InputUI";
import CheckboxUI from "../components/material-ui/CheckboxUI";
import ButtonUI from "../components/material-ui/ButtonUI";
import { AuthContext } from "../context/Auth/AuthContext";
import loginUser from "../api/login";
import loginIllustration from "../images/data.png";
import illustrationBg from "../images/illustration_bg.png";
import main_bg from "../images/login_bg.png";
import "../styles/Login.css";

const loginSchema = yup.object().shape({
  email: yup.string().email().required("Email is required field"),
  password: yup
    .string()
    .required("Password is required field")
    .min(6, "Password must contain at least 6 characters"),
});

const Login = () => {
  const [loading, setLoading] = useState(false);

  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const onLoginFormSubmit = async (loginFormData) => {
    setLoading(true);
    const loginResponse = await loginUser({
      email: loginFormData.email,
      password: loginFormData.password,
    });
    if (loginResponse?.token) {
      setLoading(false);
      dispatch({
        type: "LOGIN",
        payload: {
          token: loginResponse.token,
          userID: loginResponse.user.id,
          isAdmin: loginResponse.user.is_admin,
          phoneNumber: loginResponse.user.phonenumber,
        },
      });
      toast.success("Successfully Logged In", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate("/dashboard");
    }

    if (loginResponse?.error) {
      setLoading(false);
      toast.error(loginResponse?.error, {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (loginResponse?.errors?.email[0] || loginResponse?.errors?.password[0]) {
      setLoading(false);
      toast.error("Every field is required", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <Grid className="login" style={{ backgroundImage: `url(${main_bg})` }}>
      <div className="login-container">
        <div
          className="illustration_wrapper"
          style={{ backgroundImage: `url(${illustrationBg})` }}
        >
          <img src={loginIllustration} />
        </div>
        <div className="login_form_wrapper">
          <AvatarUI>
            <AccountCircleRoundedIcon />
          </AvatarUI>
          <h2>Hello Again!</h2>
          <InputUI
            name="email"
            label="Enter Email ID"
            type="email"
            variant="outlined"
            fullWidth={true}
            required={true}
            cssStyles={{ margin: "15px 0px 0px" }}
            register={register}
          />
          <span className="error-message">{errors["email"]?.message}</span>
          <InputUI
            name="password"
            label="Enter Password"
            type="password"
            variant="outlined"
            fullWidth={true}
            required={true}
            cssStyles={{ margin: "15px 0px 0px" }}
            register={register}
          />
          <span className="error-message">{errors["password"]?.message}</span>
          <div className="login_btn">
            <ButtonUI
              loading={loading}
              variant="contained"
              buttonText="Login"
              handleSubmit={handleSubmit}
              onFormSubmit={onLoginFormSubmit}
            />
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default Login;
