const pdfEditor = async ({
  token,
  case_number,
  document_title,
  document_file,
  document_id,
}) => {
  const formData = new FormData();
  formData.append("case_number", case_number);
  formData.append("document_title", document_title);
  formData.append("document_file", document_file);
  formData.append("document_id", document_id);

  try {
    const response = await fetch(
      `https://lvdmsup.nablasol.net/DMS/api/get_document_data/${document_id}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default pdfEditor;
