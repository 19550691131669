import React, { useState, useEffect, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import { MdSpaceDashboard, MdOutlineLogout } from "react-icons/md";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { CgFileDocument } from "react-icons/cg";
import { FaUserCog } from "react-icons/fa";
import { BiCategory } from "react-icons/bi";
import { AiOutlineFieldTime } from "react-icons/ai";
import { BsBookmarkStar } from "react-icons/bs";
import { BiTrash } from "react-icons/bi";
import { HiOutlineClipboardList } from "react-icons/hi";
import {
  TbLayoutSidebarLeftCollapse,
  TbLayoutSidebarRightCollapse,
} from "react-icons/tb";
import logoutUser from "../../api/logout";
import { AuthContext } from "../../context/Auth/AuthContext";
import logo from "../../images/logo.png";
import logo_s from "../../images/logo_collapsed.png";
import "../../styles/Sidebar.css";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <MdSpaceDashboard />,
  },
  {
    name: "Documents",
    icon: <CgFileDocument />,
    submenu: [
      {
        path: "/documents",
        name: "All Documents",
        icon: <CgFileDocument />,
      },
      {
        path: "/case-list",
        name: "Case List",
        icon: <HiOutlineClipboardList />,
      },
      {
        path: "/recent-documents",
        name: "Recents",
        icon: <AiOutlineFieldTime />,
      },
      {
        path: "/starred-documents",
        name: "Starred",
        icon: <BsBookmarkStar />,
      },
    ],
  },
  {
    name: "Settings",
    icon: <FaUserCog />,
    submenu: [
      {
        path: "/users",
        name: "Users",
        icon: <FaUserCog />,
      },
      {
        path: "/category",
        name: "Category",
        icon: <BiCategory />,
      },
    ],
  },
  {
    name: "Trash",
    icon: <BiTrash />,
    submenu: [
      {
        path: "/trash-documents",
        name: "Documents",
        icon: <CgFileDocument />,
      },
      {
        path: "/trash-category",
        name: "Category",
        icon: <BiCategory />,
      },
    ],
  },
  {
    path: "/login",
    name: "Logout",
    icon: <MdOutlineLogout />,
  },
];

const SideBar = () => {
  const [isSideBarCollapsed, setIsSideBarCollapsed] = useState(
    JSON.parse(localStorage.getItem("isSideBarCollapsed")) || false
  );

  const [isOpen, setIsOpen] = useState(false);
  const [openSubMenuName, setOpenSubMenuOpen] = useState("");

  const toggleSubmenu = (name) => {
    if (openSubMenuName === name && isOpen === true) {
      setIsOpen(false);
      openSidebar();
      return;
    }
    setOpenSubMenuOpen(name);
    setIsOpen(true);
    openSidebar();
  };

  const { instance } = useMsal();
  const redirect = useNavigate();

  const { token, isAdmin, dispatch } = useContext(AuthContext);

  useEffect(() => {
    localStorage.setItem(
      "isSideBarCollapsed",
      JSON.stringify(isSideBarCollapsed)
    );

    return () => localStorage.removeItem("isSideBarCollapsed");
  }, [isSideBarCollapsed]);

  const toggleSidebar = () => {
    setIsSideBarCollapsed((isSideBarCollapsed) => !isSideBarCollapsed);
  };

  const openSidebar = () => {
    setIsSideBarCollapsed(false);
  };

  const logoutFn = async () => {
    const data = await logoutUser({ token });
  };

  const handleLogout = async () => {
    instance
      .logoutPopup()
      .then(() => {
        logoutFn();
        dispatch({ type: "LOGOUT" });
        redirect("/login");
        toast.success("Successfully Logged Out", {
          position: "top-center",
          autoClose: 3000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <div className="sidebar-component">
      <div
        className={
          isSideBarCollapsed
            ? "collapsed-sidebar-container"
            : "sidebar-container"
        }
      >
        <div className="top-section">
          <NavLink to="/dashboard">
            {!isSideBarCollapsed ? (
              <div className="open-sidebar-img">
                <img src={logo} alt="logo" />
              </div>
            ) : (
              <img src={logo_s} height="40px" width="35px" alt="logo" />
            )}
          </NavLink>
          <div onClick={toggleSidebar} className="scrollable-bar">
            {!isSideBarCollapsed ? (
              <TbLayoutSidebarLeftCollapse />
            ) : (
              <TbLayoutSidebarRightCollapse />
            )}
          </div>
        </div>
        <section className="sidebar-list-section">
          {routes.map((route, index) => (
            <>
              {!route.submenu ? (
                <NavLink
                  to={route.path}
                  key={index}
                  className={({ isActive }) =>
                    isActive ? "active-sidebar-list" : "sidebar-list"
                  }
                  onClick={route.name === "Logout" ? handleLogout : null}
                >
                  <div className="sidebar-list-icon">{route.icon}</div>
                  <div
                    className={
                      !isSideBarCollapsed
                        ? "sidebar-list-title"
                        : "hide-sidebar-list-title"
                    }
                  >
                    {route.name}
                  </div>
                </NavLink>
              ) : (
                <>
                  {isAdmin === "0" && route.name === "Settings" ? null : (
                    <>
                      <div
                        onClick={() => toggleSubmenu(route.name)}
                        className={
                          isSideBarCollapsed
                            ? "collpased-submenu-sidebar-container"
                            : "submenu-sidebar-container"
                        }
                      >
                        <div className="submenu-header">
                          <div className="sidebar-list-icon">{route.icon}</div>
                          <div
                            className={
                              !isSideBarCollapsed
                                ? "sidebar-list-title"
                                : "hide-sidebar-list-title"
                            }
                          >
                            {route.name}
                          </div>
                        </div>
                        <div className="submenu-arrow-icon">
                          {!isSideBarCollapsed ? (
                            isOpen && route.name === openSubMenuName ? (
                              <MdKeyboardArrowUp />
                            ) : (
                              <MdKeyboardArrowDown />
                            )
                          ) : null}
                        </div>
                      </div>
                      <div className="submenu-list">
                        {isOpen &&
                        route.name === openSubMenuName &&
                        isSideBarCollapsed === false
                          ? route.submenu.map((subroute, index) => (
                              <>
                                {route.name === "Trash" &&
                                subroute.name === "Category" &&
                                isAdmin === "0" ? null : (
                                  <NavLink
                                    to={subroute.path}
                                    key={index}
                                    className={({ isActive }) =>
                                      isActive
                                        ? "active-submenu-item"
                                        : "submenu-item"
                                    }
                                  >
                                    <div className="sidebar-list-icon">
                                      {subroute.icon}
                                    </div>
                                    <div className="sidebar-list-title">
                                      {subroute.name}
                                    </div>
                                  </NavLink>
                                )}
                              </>
                            ))
                          : null}
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          ))}
        </section>
      </div>
    </div>
  );
};

export default SideBar;
