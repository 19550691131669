import React, { useContext, useState, useEffect } from "react";
import { format } from "date-fns";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import pdfHistory from "../../api/pdfHistory";
import { AuthContext } from "../../context/Auth/AuthContext";
import Loading from "../material-ui/Loading";

const PdfHistory = ({ documentID }) => {
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const [pdfHistoryData, setPdfHistoryData] = useState(false);
  const { token } = useContext(AuthContext);

  const togglePdfHistoryVisibility = () => setIsOpen(!isOpen);

  const pdfHistoryResponseFunc = async () => {
    setLoading(true);
    const response = await pdfHistory(token, documentID);
    const data = await response?.data?.[0]?.document;
    setPdfHistoryData(data);
    setLoading(false);
  };

  useEffect(() => {
    pdfHistoryResponseFunc();
  }, []);

  return (
    <div className="document-history-container">
      <div
        className="document-history-header"
        onClick={togglePdfHistoryVisibility}
      >
        <div className="document-history-title">Document History</div>
        <div className="document-history-visibility-icon">
          {isOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
        </div>
      </div>
      {isOpen ? (
        !loading ? (
          <div className="document-history-content">
            {pdfHistoryData?.map((historyData, index) => (
              <div key={index} className="document-history-data">
                <div className="document-history-data-title">
                  {historyData?.user}
                </div>
                <div className="document-history-data-date">
                  {new Date(historyData?.created_at)?.toLocaleDateString(
                    "en-US"
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="document-details-loader">Loading Data</div>
        )
      ) : null}
    </div>
  );
};

export default PdfHistory;
