import React, { useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import { Columns } from "./UserColumns";
import Loading from "../material-ui/Loading";
import "../../styles/Table.css";

const UserTable = ({ loading, userTableData }) => {
  const columns = useMemo(() => Columns, []);
  const data = useMemo(() => userTableData, [userTableData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: columns,
        data: data,
        initialState: {
          hiddenColumns: ["id", "first_name", "last_name"],
        },
      },
      useSortBy
    );

  return (
    <div className="table-content">
      {!loading ? (
        <div>
          <table className="documents-table user-tables" {...getTableProps()}>
            <thead className="documents-table-header other-tables-header">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      <span>{column.render("Header")}</span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              className="documents-table-body other-tables-body"
              {...getTableBodyProps()}
            >
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      if (cell.column.Header == "Name") {
                        return (
                          <td {...cell.getCellProps()}>
                            {`${cell.row.values.first_name} ${cell.row.values.last_name}`}
                          </td>
                        );
                      }
                      if (cell.column.Header == "Phone") {
                        return cell.row.values.phonenumber ? (
                          <td {...cell.getCellProps()}>
                            {`${cell.row.values.phonenumber}`}
                          </td>
                        ) : (
                          <td {...cell.getCellProps()}>NA</td>
                        );
                      }
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default UserTable;
