const deleteAll = async ({ token, id }) => {
	try {
		const response = await fetch(
			`https://lvdmsup.nablasol.net/DMS/api/delete_all_document?ids=${id}`,
			{
				method: 'DELETE',
				headers: {
					Accept: 'application/json',
					Authorization: `Bearer ${token}`
				}
			}
		);
		const data = await response.json();
		return data;
	} catch (error) {
		return error;
	}
};

export default deleteAll;
