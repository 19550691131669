const deleteRequestedCase = async ({ token, docId }) => {
  try {
    const response = await fetch(
      `https://lvdmsup.nablasol.net/DMS/api/delete_docu_list?docId=${docId}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default deleteRequestedCase;
