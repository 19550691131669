import React, { useState, useContext, useEffect } from "react";
import { BiLinkAlt } from "react-icons/bi";
import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { TiDeleteOutline } from "react-icons/ti";
import { HiDownload } from "react-icons/hi";
import getAllDocumentList from "../../../api/getAllDocumentList";
import ButtonUI from "../../material-ui/ButtonUI";
import { AuthContext } from "../../../context/Auth/AuthContext";
import linkDocument from "../../../api/linkDocument";
import "../../../styles/LinkPopup.css";
import downloadLink from "../../../api/downloadLink";

const LinkPopup = ({
  tableValues,
  caseNo,
  caseID,
  setCategoryUpdatedToTrue,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allDocList, setAllDocList] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const { token } = useContext(AuthContext);

  const onLinkPopupLoad = async () => {
    setLoading(true);
    const data = await getAllDocumentList({ token, caseNo, caseID });
    const filteredDocList =
      data?.data?.data !== null
        ? data?.data?.data.map((doc) => {
            return {
              label: doc.title,
              DocumentID: doc.DocumentID,
              Platform: doc.Platform,
            };
          })
        : [];
    setAllDocList(filteredDocList);
    setLoading(false);
  };

  const handleClickOpen = () => {
    onLinkPopupLoad();
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedDocument(null);
    setOpen(false);
  };

  const openLinkInNewTab = async () => {
    const versionID = tableValues.download_link_c.slice(31);
    const downloadLinkFormResponse = await downloadLink({
      token: token,
      caseNo: caseNo,
      versionId: versionID.substring(0, versionID.length - 1),
      platform: "AWS",
    });

    if (downloadLinkFormResponse?.code === 200) {
      const link = downloadLinkFormResponse?.data?.data;
      window.open(`https://sugar.consumerlaw.com/${link}`);
    }
  };

  const onLinkDocumentButton = async () => {
    const linkDocPayload = [
      {
        docId: tableValues.doc_id,
        caseNo: caseNo,
        fileName: tableValues.name,
        versionID: selectedDocument.DocumentID,
        platform: selectedDocument.Platform,
      },
    ];

    const onLinkDocumentButtonResponse = await linkDocument({
      token: token,
      linkDocPayload: linkDocPayload,
    });

    if (onLinkDocumentButtonResponse.code === 200) {
      setOpen(false);
      setCategoryUpdatedToTrue();
      toast.success("Document linked successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="single-link-popup-container">
      <ButtonUI
        formButton={false}
        variant="outlined"
        buttonText={
          tableValues.status_c === "Approved" ? <HiDownload /> : <BiLinkAlt />
        }
        style={{
          backgroundColor: "#17a2b8",
          color: " white",
          marginRight: "5px",
        }}
        onClickFunc={
          tableValues.status_c === "Approved"
            ? openLinkInNewTab
            : handleClickOpen
        }
      />
      {tableValues.status_c !== "Approved" ? (
        open ? (
          <div className="single-doc-link-popup-content">
            {!loading ? (
              allDocList.length !== 0 ? (
                <>
                  <Autocomplete
                    className="select-doc-select-ui"
                    id="select-file-option"
                    options={allDocList}
                    sx={{ width: 350 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Document" />
                    )}
                    value={selectedDocument}
                    onChange={(e, selectedOption) =>
                      setSelectedDocument(selectedOption)
                    }
                  />
                  <div className="link-popup-action-btn">
                    <ButtonUI
                      formButton={false}
                      variant="contained"
                      buttonText="Cancel"
                      autoFocus={true}
                      style={{
                        backgroundColor: "rgb(215 18 18)",
                        color: "white",
                        letterSpacing: "0.06rem",
                        padding: "3px 15px",
                        marginRight: "5px",
                        marginTop: "10px",
                        marginBottom: "5px",
                      }}
                      onClickFunc={handleClose}
                    />
                    <ButtonUI
                      formButton={false}
                      variant="contained"
                      buttonText="Submit"
                      autoFocus={true}
                      style={{
                        backgroundColor:
                          selectedDocument !== null ? "#007bff" : "white",
                        color: selectedDocument !== null ? "white" : "#007bff",
                        border:
                          selectedDocument !== null
                            ? "none"
                            : "1px solid #007bff",
                        letterSpacing: "0.06rem",
                        padding: "3px 15px",
                        marginRight: "5px",
                        marginTop: "10px",
                        marginBottom: "5px",
                      }}
                      disabled={selectedDocument !== null ? false : true}
                      onClickFunc={onLinkDocumentButton}
                    />
                  </div>
                </>
              ) : (
                <div className="no-doc-link-popup-data">
                  <div>No Documents found</div>
                  <ButtonUI
                    formButton={false}
                    variant="contained"
                    buttonText={<TiDeleteOutline />}
                    autoFocus={true}
                    style={{
                      backgroundColor: "transparent",
                      color: "rgb(215 18 18)",
                      padding: "3px",
                      marginRight: "5px",
                      marginTop: "10px",
                      marginLeft: "10px",
                      marginBottom: "10px",
                    }}
                    onClickFunc={handleClose}
                  />
                </div>
              )
            ) : (
              <div className="loading-link-popup-data">Loading Data</div>
            )}
          </div>
        ) : null
      ) : null}
    </div>
  );
};

export default LinkPopup;
