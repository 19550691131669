import React, { useState, useContext } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { IoCalendarNumber } from "react-icons/io5";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const Calendar = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="dialog-modal">
      <div onClick={handleClickOpen} className="deleteBtn">
        <IoCalendarNumber />
      </div>
      <Dialog
        fullWidth="true"
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FullCalendar
              plugins={[dayGridPlugin]}
              initialView="dayGridMonth"
              dayMaxEvents={2}
              weekends={false}
              events={[
                {
                  title: "Test Document 1",
                  start: "2023-05-01",
                  end: "2023-05-05",
                },
                { title: "Test Document 2", date: "2023-05-04" },
                {
                  title: "Test Document 3",
                  start: "2023-05-15",
                  end: "2023-05-18",
                },
                {
                  title: "Test Document 4",
                  start: "2023-05-17",
                  end: "2023-05-19",
                },
                {
                  title: "Test Document 5",
                  start: "2023-05-16",
                  end: "2023-05-18",
                },
                {
                  title: "Test Document 6",
                  start: "2023-05-22",
                  end: "2023-05-25",
                },
                {
                  title: "Test Document 7",
                  start: "2023-05-30",
                  end: "2023-05-30",
                },
                {
                  title: "Test Document 8",
                  start: "2023-06-30",
                  end: "2023-07-05",
                },
              ]}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Calendar;
