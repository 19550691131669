const addDocument = async ({
  token,
  user_id,
  case_number,
  document_title,
  document_desc,
  category_id,
  document_file,
}) => {
  const formData = new FormData();
  formData.append("user_id", user_id);
  formData.append("case_number", case_number);
  formData.append("document_title", document_title);
  formData.append("document_desc", document_desc);
  formData.append("category_id", category_id);
  formData.append("document_file", document_file);

  try {
    const response = await fetch(
      "https://lvdmsup.nablasol.net/DMS/api/save_documents",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default addDocument;
