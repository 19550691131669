import React, { useState, useEffect, useContext } from "react";
import ReactPaginate from "react-paginate";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";
import TextField from "@mui/material/TextField";
import Navbar from "../components/common/Navbar";
import userData from "../api/user";
import UserTable from "../components/user-table/UserTable";
import Loading from "../components/material-ui/Loading";
import { AuthContext } from "../context/Auth/AuthContext";
import "../styles/Users.css";

const Users = () => {
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [globalSearch, setGlobalSearch] = useState("");

  const [userResponse, setUserResponse] = useState([]);
  const [api, setApi] = useState(
    `https://lvdmsup.nablasol.net/DMS/api/get_users?limit=20&offset=0`
  );

  let limit = 20;
  const offset = currentPage * limit;

  let global_search = globalSearch ? `&global_search=${globalSearch}` : "";

  const { token } = useContext(AuthContext);

  const UserResponseFunc = async () => {
    setLoading(true);
    const users = await userData({ token, api });
    const data = await users?.data?.[0];
    setPageCount(Math.ceil(data?.allUsersCount / limit));
    setUserResponse(data?.users);
    setLoading(false);
  };

  useEffect(() => {
    UserResponseFunc();
  }, [api]);

  const onGlobalSearchChange = (e) => {
    setGlobalSearch(e.target.value);
  };

  useEffect(() => {
    setApi(
      `https://lvdmsup.nablasol.net/DMS/api/get_users?limit=${limit}&offset=${offset}${global_search}`
    );
  }, [currentPage]);

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      setApi(
        `https://lvdmsup.nablasol.net/DMS/api/get_users?limit=${20}&offset=${0}${global_search}`
      );
    }, 1000);

    return () => clearTimeout(debounceFn);
  }, [globalSearch]);

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  return (
    <div className="user-container">
      <div className="navbar">
        <Navbar />
      </div>
      <div className="user-table">
        <div className="user-content">
          <div className="user-table-header">
            <TextField
              name="globalSearch"
              label="Search"
              type="text"
              autoComplete="off"
              value={globalSearch}
              onChange={onGlobalSearchChange}
              variant="outlined"
              InputProps={{
                endAdornment: <AiOutlineSearch />,
              }}
            />
          </div>
          {!loading ? (
            userResponse.length > 0 ? (
              <div className="user-table-content">
                <UserTable loading={loading} userTableData={userResponse} />
                <div className="user-table-pagination">
                  <ReactPaginate
                    previousLabel={<MdOutlineArrowBackIos />}
                    nextLabel={<MdOutlineArrowForwardIos />}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    forcePage={currentPage}
                    containerClassName={"user-pagination"}
                    previousLinkClassName={"user-pagination-link"}
                    nextLinkClassName={"user-pagination-link"}
                    disabledClassName={"user-pagination-link-disabled"}
                    activeClassName={"user-pagination-link-active"}
                  />
                </div>
              </div>
            ) : (
              <h5 className="no-user-data">No Data Available</h5>
            )
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </div>
  );
};

export default Users;
