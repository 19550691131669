const uploadDocument = async ({
  token,
  CaseId,
  CaseNo,
  customFileName,
  customComment,
  docSubCategory,
  file,
}) => {
  const formData = new FormData();
  formData.append("CaseId", CaseId);
  formData.append("CaseNo", CaseNo);
  formData.append("customFileName", customFileName);
  formData.append("customComment", customComment);
  formData.append("docSubCategory", docSubCategory);
  file.forEach((file) => {
    formData.append("file[]", file);
  });

  try {
    const response = await fetch(
      `https://lvdmsup.nablasol.net/DMS/api/upload_dms_documents`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default uploadDocument;
