const addCategory = async ({ token, name, description, badge, bgColor }) => {
  const formData = new FormData();
  formData.append("name", name);
  formData.append("description", description);
  formData.append("badge", badge);
  if (bgColor === "#000000") {
    formData.append("bg_color", "rgba(153, 102, 255, 0.2)");
  } else {
    formData.append("bg_color", bgColor);
  }
  try {
    const response = await fetch(
      "https://lvdmsup.nablasol.net/DMS/api/add_registercategory",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default addCategory;
