const editUser = async ({ token, id, name, description, badge, bgColor }) => {
  try {
    const response = await fetch(
      `https://lvdmsup.nablasol.net/DMS/api/update_registercategory/${id}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: new URLSearchParams({
          name: name,
          description: description,
          badge: badge,
          bg_color: bgColor,
        }),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default editUser;
