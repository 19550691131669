export const Columns = [
  {
    Header: "ID",
    accessor: "DocumentID",
  },
  {
    Header: "Case ID",
    accessor: "caseid",
  },
  {
    Header: "Document Url",
    accessor: "download_url",
  },
  {
    Header: "PDF Url",
    accessor: "docUrl",
  },

  {
    Header: "Document Type",
    accessor: "DocumentType",
  },
  {
    Header: "Name",
    accessor: "title",
  },
  {
    Header: "Platform",
    accessor: "Platform",
  },
  {
    Header: "Version",
    accessor: "versionid",
  },
  {
    Header: "Category",
    accessor: "Category",
  },
  {
    Header: "Sub Category",
    accessor: "document_sub_category",
  },
  {
    Header: "Uploaded",
    accessor: "createdOn",
  },
];
