import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, ChartDataLabels, Tooltip, Legend);

const PieChart = ({
  logicdocument,
  MMSdocument,
  Manualdocument,
  APIdocument,
  Sugardocument,
}) => {
  const dataValue = [
    logicdocument,
    MMSdocument,
    Manualdocument,
    APIdocument,
    Sugardocument,
  ];

  const totalDocuments = dataValue.reduce((data, acc) => acc + data, 0);

  const data = {
    labels: [
      "Logic Document",
      "MMS Document",
      "Manual Document",
      "API Document",
      "Sugar Document",
    ],
    datasets: [
      {
        label: "# of Documents",
        data: dataValue,
        backgroundColor: [
          "rgba(255, 99, 132, 0.5)",
          "rgba(54, 162, 235, 0.5)",
          "rgba(255, 206, 86, 0.5)",
          "rgba(105, 192, 192, 0.5)",
          "rgba(153, 102, 255, 0.5)",
        ],
        borderColor: ["#fff", "#fff", "#fff", "#fff", "#fff"],
        borderWidth: 1,
      },
    ],
  };

  // "rgb(28,51,83)",
  //         "rgb(70,127,207)",
  //         "rgb(200,217,241)",
  //         "rgb(126,165,221)",
  //         "rgba(153, 102, 255, 0.6)",

  return (
    <Pie
      data={data}
      height="400px"
      width="400px"
      options={{
        maintainAspectRatio: false,
        plugins: {
          ChartDataLabels,
          datalabels: {
            font: {
              size: 16,
            },
            display: function (context) {
              let index = context.dataIndex;
              let value = (
                (context.dataset.data[index] * 100) /
                totalDocuments
              ).toFixed(1);
              return value > 0;
            },
            formatter: function (value) {
              let formatValue =
                ((value * 100) / totalDocuments).toFixed(1) + "%";
              return formatValue;
            },
          },
          tooltip: {
            titleFont: {
              size: 14,
            },
            bodyFont: {
              size: 14,
            },
            callbacks: {
              title: function (context) {
                return context[0].label;
              },
              label: function (context) {
                let index = context.dataIndex;
                let value = (
                  (context.dataset.data[index] * 100) /
                  totalDocuments
                ).toFixed(1);
                return `${context.formattedValue}  (${value}%)`;
              },
            },
          },
          legend: {
            position: "bottom",
            labels: {
              usePointStyle: true,
              pointStyle: "circle",
              padding: 20,
            },
          },
          title: {
            display: true,
            text: "Documents Category",
            padding: {
              top: 10,
              bottom: 30,
            },
            font: { size: 18 },
            color: "#007bff",
          },
        },
      }}
    />
  );
};

export default PieChart;
