export const Columns = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Starred",
    accessor: "favourite",
  },
  {
    Header: "Case ID",
    accessor: "case_number",
  },
  {
    Header: "File",
    accessor: "document_type",
  },
  {
    Header: "Name",
    accessor: "document_title",
  },
  {
    Header: "Document Description",
    accessor: "document_desc",
  },
  {
    Header: "Case Type",
    accessor: "case_cat_type",
  },
  {
    Header: "Category",
    accessor: "badge",
  },
  {
    Header: "Category Name",
    accessor: "name",
  },
  {
    Header: "Category Color",
    accessor: "bg_color",
  },

  {
    Header: "Uploaded",
    accessor: "created_at",
  },
  {
    Header: "Status",
    accessor: "status_id",
    Cell: ({ row }) => <>Pending</>,
  },
];
