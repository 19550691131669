const DigitalSignatureReducer = (state, action) => {
  switch (action.type) {
    case "SAVEDIGITALSIGN": {
      return {
        dmsDigitalSignature: action.payload.dmsDigitalSignature,
      };
    }

    case "DELETEDIGITALSIGN": {
      return {
        dmsDigitalSignature: "",
      };
    }

    default:
      return state;
  }
};

export default DigitalSignatureReducer;
