const updateRequestedDocumentCategory = async ({ token, docID, category }) => {
  try {
    const response = await fetch(
      `https://lvdmsup.nablasol.net/DMS/api/update_docu_category?docId=${docID}&documentCategory=${category}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default updateRequestedDocumentCategory;
