import React, { useState, useContext, useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { RiCloseCircleFill } from "react-icons/ri";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import ButtonUI from "../../material-ui/ButtonUI";
import { AuthContext } from "../../../context/Auth/AuthContext";
import "../../../styles/UploadDocumentPopup.css";
import { SilentIframeClient } from "@azure/msal-browser/dist/internals";
import uploadDocument from "../../../api/uploadDocument";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <RiCloseCircleFill />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "15px",
  borderWidth: 2,
  borderRadius: 2,
  cursor: "pointer",
  borderColor: "rgba(165 ,155 ,155,0.7)",
  borderStyle: "dashed",
  backgroundColor: "rgb(234, 234 ,234 ,0.5)",
  color: "rgb(0 123 255 / 72%)",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const UploadDocumentsPopup = ({ caseNo, caseID, setDocumentDeletedToTrue }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [addName, setAddName] = useState("");
  const [comment, setComment] = useState("");
  const [selectedDocument, setSelectedDocument] = useState("");

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: { "image/*": [], "application/pdf": [] } });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const { token } = useContext(AuthContext);

  const docCategoryArray = [
    "Engagement Documents",
    "Legal Correspondence",
    "Filings",
    "Drafts",
    "Client Documents",
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onUploadDocumentFormSubmit = async () => {
    setLoading(true);
    const uploadDocumentFormResponse = await uploadDocument({
      token: token,
      CaseId: caseID,
      CaseNo: caseNo,
      customFileName: addName,
      customComment: comment,
      docSubCategory: selectedDocument,
      file: acceptedFiles,
    });

    if (uploadDocumentFormResponse.code === 200) {
      setDocumentDeletedToTrue();
      setOpen(false);
      setLoading(false);
      toast.success("Document is uploaded successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="dialog-modal">
      <ButtonUI
        formButton={false}
        variant="contained"
        buttonText="Upload Doc"
        style={{
          width: "135px",
          height: "35px",
          color: "#000",
          marginRight: "10px",
          backgroundColor: "#2b93c8d0",
          boxShadow: "none",
          fontWeight: "200",
          borderRadius: "4px",
          letterSpacing: "0.07rem",
        }}
        onClickFunc={handleClickOpen}
      ></ButtonUI>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          className="edit-doc-modal"
          onClose={handleClose}
        >
          <div className="popup-header-svg-icon">
            <AiOutlineCloudUpload />
          </div>
          <div className="popup-header-title">Upload Document</div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div className="upload-file-in-all-docs">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <span>
                {acceptedFiles.length !== 0 ? (
                  acceptedFiles?.map((file, index) => (
                    <div key={index}>{file.name}</div>
                  ))
                ) : (
                  <>
                    Drag 'n' drop file here, or click to select file <br />
                    Press and hold ctrl for multiple file upload
                  </>
                )}
              </span>
            </div>
            <div>
              <FormControl className="select-doc-select-ui">
                <InputLabel
                  className="select-doc-select-label"
                  id="select-label"
                >
                  Select Document
                </InputLabel>
                <Select
                  sx={{ margin: "10px 0px" }}
                  labelId="select-label"
                  variant="outlined"
                  id="simple-select"
                  value={selectedDocument}
                  onChange={(e) => setSelectedDocument(e.target.value)}
                  label="Select Document"
                >
                  {docCategoryArray.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <TextField
                name="name"
                label="Name"
                type="text"
                autoComplete="off"
                value={addName}
                onChange={(e) => setAddName(e.target.value)}
                className="uploaded-doc-textfield"
                variant="outlined"
              />
            </div>
            <div>
              <TextField
                name="comment"
                label="Comment"
                type="text"
                autoComplete="off"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                className="uploaded-doc-textfield-two"
                variant="outlined"
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="popup_submit_btn">
            <ButtonUI
              loading={loading}
              formButton={false}
              variant="contained"
              buttonText="Submit"
              autoFocus={true}
              disabled={
                acceptedFiles?.length > 0 &&
                selectedDocument !== "" &&
                addName !== ""
                  ? false
                  : true
              }
              onClickFunc={onUploadDocumentFormSubmit}
            />
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default UploadDocumentsPopup;
