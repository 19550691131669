import React from "react";
import { MdContentCopy } from "react-icons/md";
import { toast } from "react-toastify";
import ButtonUI from "../../material-ui/ButtonUI";

const CopyLinkPopup = ({ tableValues }) => {
  const copyLink = () => {
    const link = `https://sugar.consumerlaw.com/downloadaws.php?CaseNo=${tableValues.caseid}&ServiceType=Download&VersionID=${tableValues.DocumentID}&platform=${tableValues.Platform}&linkType=directDownload`;
    navigator.clipboard.writeText(link);
    toast.success("Link copied successfully", {
      position: "top-center",
      autoClose: 3000,
      theme: "dark",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <ButtonUI
      formButton={false}
      variant="outlined"
      buttonText={<MdContentCopy />}
      style={{
        backgroundColor: "#28a745",
        // boxShadow: "0 0 0.4rem #2a9ceb57, inset 0 0 0 2px #2a9ceb57",
        color: "white",
        marginRight: "5px",
      }}
      onClickFunc={copyLink}
    />
  );
};

export default CopyLinkPopup;
