const editDocument = async ({
  token,
  id,
  case_number,
  document_title,
  document_desc,
  category_id,
}) => {
  try {
    const response = await fetch(
      `https://lvdmsup.nablasol.net/DMS/api/update_document/${id}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: new URLSearchParams({
          case_number: case_number,
          document_title: document_title,
          document_desc: document_desc,
          category_id: category_id,
        }),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default editDocument;
