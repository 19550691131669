import React, { useState, useContext, useEffect } from "react";
import { BiLinkAlt } from "react-icons/bi";
import { toast } from "react-toastify";
import { RiCloseCircleFill } from "react-icons/ri";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import ButtonUI from "../../material-ui/ButtonUI";
import { AuthContext } from "../../../context/Auth/AuthContext";
import getAllDocumentList from "../../../api/getAllDocumentList";
import linkDocument from "../../../api/linkDocument";
import "../../../styles/BulkLinkPopup.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <RiCloseCircleFill />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BulkLinkPopup = ({
  caseNo,
  caseID,
  selectedRows,
  setCategoryUpdatedToTrue,
}) => {
  const [open, setOpen] = useState(false);
  const [tags, setTags] = useState(selectedRows);
  const [loading, setLoading] = useState(false);
  const [allDocList, setAllDocList] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState("");

  const { token } = useContext(AuthContext);

  const onLinkPopupLoad = async () => {
    setOpen(true);
    setLoading(true);
    const data = await getAllDocumentList({ token, caseNo, caseID });
    setAllDocList(data?.data?.data !== null ? data?.data?.data : []);
    setLoading(false);
  };

  const removeTag = (index) => {
    const filteredTags = tags.filter((tag, i) => i !== index);
    setTags(filteredTags);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setSelectedDocumentFn = (doc) => {
    setSelectedDocument(doc);
  };

  const onLinkDocumentButton = async () => {
    const linkDocPayload = tags.map((tag) => {
      return {
        docId: tag.doc_id,
        caseNo: caseNo,
        fileName: tag.name,
        versionID: selectedDocument.DocumentID,
        platform: selectedDocument.Platform,
      };
    });

    const onLinkDocumentButtonResponse = await linkDocument({
      token,
      linkDocPayload,
    });

    if (onLinkDocumentButtonResponse.code === 200) {
      setOpen(false);
      setCategoryUpdatedToTrue();
      toast.success("Document linked successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="dialog-modal">
      <div
        onClick={() => {
          onLinkPopupLoad();
        }}
        className="deleteBtn"
      >
        Link Docs
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          className="edit-doc-modal"
          onClose={handleClose}
        >
          <div className="popup-header-svg-icon">
            <BiLinkAlt />
          </div>
          <div className="popup-header-title">Link Document</div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div className="link-popup-content">
            {!loading ? (
              allDocList.length !== 0 ? (
                <>
                  <div className="link-tags-input-container">
                    <div className="link-tags-input-content">
                      {tags.map((tag, index) => (
                        <div key={index} className="link-tag-item">
                          <span className="text">{tag.name}</span>
                          <span
                            className="close"
                            onClick={() => removeTag(index)}
                          >
                            <RiCloseCircleFill />
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <FormControl className="select-doc-select-ui">
                    <InputLabel
                      className="select-doc-select-label"
                      id="select-label"
                    >
                      Select Document
                    </InputLabel>
                    <Select
                      sx={{ margin: "10px 0px" }}
                      labelId="select-label"
                      variant="outlined"
                      id="simple-select"
                      value={selectedDocument}
                      onChange={(e) => setSelectedDocumentFn(e.target.value)}
                      label="Select Document"
                    >
                      {allDocList.map((option) => (
                        <MenuItem key={option.title} value={option}>
                          {option.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              ) : (
                <div>No Documents found</div>
              )
            ) : (
              "Loading Data"
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <div className="popup_submit_btn">
            <ButtonUI
              formButton={false}
              variant="contained"
              buttonText="Submit"
              disabled={
                tags?.length > 0 && selectedDocument !== "" ? false : true
              }
              autoFocus={true}
              onClickFunc={() => {
                onLinkDocumentButton();
              }}
            />
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default BulkLinkPopup;
