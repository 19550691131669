import { createContext, useReducer, useEffect } from "react";
import DigitalSignatureReducer from "./DigitalSignatureReducer";

const INITIAL_STATE = {
  dmsDigitalSignature:
    JSON.parse(localStorage.getItem("dmsDigitalSignature")) || "",
};

export const DigitalSignatureContext = createContext(INITIAL_STATE);

export const DigitalSignatureContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(DigitalSignatureReducer, INITIAL_STATE);

  useEffect(() => {
    localStorage.setItem(
      "dmsDigitalSignature",
      JSON.stringify(state.dmsDigitalSignature)
    );
  }, [state.dmsDigitalSignature]);

  return (
    <DigitalSignatureContext.Provider
      value={{
        dmsDigitalSignature: state.dmsDigitalSignature,
        dispatch,
      }}
    >
      {children}
    </DigitalSignatureContext.Provider>
  );
};
