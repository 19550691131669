const pdfBase64Data = async (token, id) => {
  try {
    const response = await fetch(
      `https://lvdmsup.nablasol.net/DMS/api/sample_blob_data/${id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.json();
  } catch (error) {
    return error;
  }
};

export default pdfBase64Data;
