const linkDocument = async ({ token, linkDocPayload }) => {
  try {
    const response = await fetch(
      `https://lvdmsup.nablasol.net/DMS/api/link_dms_document`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(linkDocPayload),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default linkDocument;
