import React, { useMemo, useContext } from "react";
import { format } from "date-fns";
import { useTable, useRowSelect } from "react-table";
import { Columns } from "./Columns";
import Loading from "../material-ui/Loading";
import { AuthContext } from "../../context/Auth/AuthContext";
import "../../styles/Table.css";

const Table = ({
  loading,
  documentResponse,
  addDocumentTab,
  changeDocumentFavouriteStateFunc,
  setDocumentUpdatedToTrue,
  setDocumentDeletedToTrue,
  allFavourite,
  documentTableData,
  categoryOptions,
}) => {
  const columns = useMemo(() => Columns, []);
  const data = useMemo(() => documentResponse, [documentResponse]);

  const { token } = useContext(AuthContext);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    getToggleAllRowsSelectedProps,
  } = useTable({
    columns: columns,
    data: data,
    initialState: {
      hiddenColumns: ["id", "contact_id", "oppId", "preferred_language"],
    },
  });

  return (
    <div className="table-content">
      {!loading ? (
        <table
          className="documents-table case-list-tables"
          {...getTableProps()}
        >
          <thead className="documents-table-header">
            {headerGroups.map((headerGroup, index) => (
              <tr
                className="documents-table-header-row"
                key={index}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column, index) => {
                  return (
                    <th className="documents-table-header-data" key={index}>
                      <span className="document-table-select-row">
                        {column.render("Header")}
                      </span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="documents-table-body" {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  key={index}
                  className="document-table-body-row"
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, index) => {
                    switch (cell.column.Header) {
                      case "Case ID":
                        return (
                          <td key={index}>
                            <span
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => addDocumentTab(cell.row.values)}
                            >
                              {cell.value}
                            </span>
                          </td>
                        );

                      case "Date":
                        return (
                          <td key={index}>
                            {format(new Date(cell.value), "MM-dd-yyyy")}
                          </td>
                        );

                      default:
                        return (
                          <td key={index} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Table;
