import React, { useState, useContext, useEffect } from "react";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { IoCalendarNumber } from "react-icons/io5";
import { AuthContext } from "../../context/Auth/AuthContext";
import profileIcon from "../../images/no-image-icon.png";
import Dropdown from "./Dropdown";
import Calendar from "./Calendar";

import NotificationDropdown from "./NotificationDropdown";
import "../../styles/Navbar.css";

const Navbar = () => {
  const [profileImgClicked, setProfileImgClicked] = useState(false);
  const [notificationIconClicked, setNotificationIconClicked] = useState(false);
  const [calc, setCalc] = useState(false);

  const { imageLink } = useContext(AuthContext);

  const closeNotificationBox = () => {
    setNotificationIconClicked(false);
  };

  // const getNotification = async () => {
  //   setNotificationIconClicked(!notificationIconClicked);
  //   const data = await notificationData({ token });
  // };

  return (
    <div className="navbar-container">
      <div className="navbar-icon-container">
        <div className="dropdown">
          <Calendar />
        </div>
        <div className="dropdown">
          <NotificationsOutlinedIcon
            onClick={() => {
              setNotificationIconClicked(!notificationIconClicked);
              setProfileImgClicked(false);
            }}
            className="navbar-icon"
          />
          {notificationIconClicked && (
            <NotificationDropdown closeNotificationBox={closeNotificationBox} />
          )}
        </div>
        <div
          className="dropdown"
          onClick={() => {
            setNotificationIconClicked(false);
            setProfileImgClicked(!profileImgClicked);
          }}
        >
          <img
            src={imageLink ? imageLink : profileIcon}
            height="10px"
            width="10px"
            className="navbar-icon"
            alt="profile-icon"
          />
          {profileImgClicked && <Dropdown />}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
