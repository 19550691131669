import React, { useState, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import PrivateRoute from "./components/Routes/PrivateRoute";
import Category from "./pages/Category";
import Dashboard from "./pages/Dashboard";
import Documents from "./pages/Documents";
import Login from "./pages/Login";
import Users from "./pages/Users";
import Recents from "./pages/Recents";
import Starred from "./pages/Starred";
import { AuthContext } from "../src/context/Auth/AuthContext";
import TrashDocuments from "./pages/TrashDocuments";
import TrashCategory from "./pages/TrashCategory";
import CaseList from "./pages/CaseList";
import Callback from "./pages/Callback";
import "./styles/App.css";

function App() {
  const { isAdmin } = useContext(AuthContext);

  return (
    <div className="app">
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/callback" element={<Callback />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/documents"
            element={
              <PrivateRoute>
                <Documents />
              </PrivateRoute>
            }
          />
          <Route
            path="/case-list"
            element={
              <PrivateRoute>
                <CaseList />
              </PrivateRoute>
            }
          />
          {isAdmin === "0" ? null : (
            <>
              <Route
                path="/users"
                element={
                  <PrivateRoute>
                    <Users />
                  </PrivateRoute>
                }
              />
              <Route
                path="/category"
                element={
                  <PrivateRoute>
                    <Category />
                  </PrivateRoute>
                }
              />
            </>
          )}
          <Route
            path="/recent-documents"
            element={
              <PrivateRoute>
                <Recents />
              </PrivateRoute>
            }
          />
          <Route
            path="/starred-documents"
            element={
              <PrivateRoute>
                <Starred />
              </PrivateRoute>
            }
          />
          <Route
            path="/trash-documents"
            element={
              <PrivateRoute>
                <TrashDocuments />
              </PrivateRoute>
            }
          />
          {isAdmin === "0" ? null : (
            <Route
              path="/trash-category"
              element={
                <PrivateRoute>
                  <TrashCategory />
                </PrivateRoute>
              }
            />
          )}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
