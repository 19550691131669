import React, { useState, useContext, useEffect } from "react";
import { RiCloseCircleFill } from "react-icons/ri";
import { IoMdShareAlt } from "react-icons/io";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import ButtonUI from "../../material-ui/ButtonUI";
import { AuthContext } from "../../../context/Auth/AuthContext";
import "../../../styles/ShareDocumentPopup.css";
import shareDocuments from "../../../api/shareDocument";
import sendEmail from "../../../api/sendEmail";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <RiCloseCircleFill />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ShareDocumentPopup = ({ tableValues, oppId, preferredLanguage }) => {
  const [open, setOpen] = useState(false);
  const [selectedShareOption, setSelectedShareOption] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [client, setClient] = useState("");
  const { token, userID, phoneNumber } = useContext(AuthContext);

  const shareList = ["Email", "SMS", "Whatsapp"];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onShareButtonClick = async () => {
    setOpen(true);

    const shareDocumentFormResponse = await shareDocuments({
      token: token,
      caseId: tableValues.caseid,
      opportunityId: oppId,
    });

    if (shareDocumentFormResponse?.code === 200) {
      setEmail(shareDocumentFormResponse?.data?.[0]?.email_address);
      setPhone(shareDocumentFormResponse?.data?.[0]?.phone_mobile);
      const client = `${shareDocumentFormResponse?.data?.[0]?.first_name} ${shareDocumentFormResponse?.data?.[0]?.last_name}`;

      setClient(client);
      const messageToBeShown =
        preferredLanguage === "en_us"
          ? `Dear ${client}, Consumer Law Group, LLC has sent you the following document below. Please click on the link below to download the document! Thank you for entrusting Consumer Law Group, LLC with your important legal matter. Your satisfaction is our top priority!`
          : `Estimado ${client}, Consumer Law Group, LLC le ha enviado el siguiente documento a continuación. ¡Haga clic en el siguiente enlace para descargar el documento! Gracias por confiar en Consumer Law Group, LLC su importante asunto legal. ¡Su satisfacción es nuestra máxima prioridad!`;
      setMessage(messageToBeShown);
    }
  };

  const generateUniqueID = () => {
    let u = "";
    for (let i = 0; i < 36; i++) {
      let c = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"[i - 1];
      let r = (Math.random() * 16) | 0;
      let v = c == "x" ? r : (r & 0x3) | 0x8;
      u += c == "-" || c == "4" ? c : v.toString(16);
    }
    return u;
  };

  const generateURL = () => {
    return `https://documents.consumerlaw.com/getdocuments.php?unique_id=${generateUniqueID()}`;
  };

  const shareDocument = async () => {
    const rejectDocumentFormResponse = await sendEmail({
      token: token,
      selectedShareOption: selectedShareOption,
      sendData: {
        email: email,
        fileName: tableValues.title.split(" ").join("%20"),
        uniqueID: generateUniqueID(),
        versionId: tableValues.DocumentID,
        number: phone,
        userID: userID,
        caseID: tableValues.caseid,
        name: client.split(" ").join("%20"),
        lang: preferredLanguage === "en_us" ? "en_us" : "es_LA",
        fromNumber: phoneNumber,
        fileType: tableValues.DocumentID + "." + tableValues.DocumentType,
        data:
          selectedShareOption !== "Email"
            ? {
                module: "Cases",
                id: tableValues.caseId,
                assigned_user: userID,
                message: `Message: \n ${message}\n\n URL: ${generateURL()}`,
                toNumber: phone,
                currentModuleWithRelatedModules: {
                  lead: {
                    leadid: tableValues.caseId,
                    name: client,
                    phone_mobile: phone,
                  },
                },
              }
            : null,
      },
    });
    if (rejectDocumentFormResponse?.code === 200) {
      setOpen(false);
      toast.success("Document is shared successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="dialog-modal">
      <ButtonUI
        formButton={false}
        variant="outlined"
        buttonText={<IoMdShareAlt />}
        style={{
          backgroundColor: "#17a2b8",
          color: "white",
          marginRight: "5px",
        }}
        onClickFunc={onShareButtonClick}
      />
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          className="edit-doc-modal"
          onClose={handleClose}
        >
          <div className="popup-header-svg-icon">
            <IoMdShareAlt />
          </div>
          <div className="popup-header-title">Share Document</div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <FormControl className="share-doc-select-ui">
            <InputLabel className="share-doc-select-label" id="select-label">
              Share
            </InputLabel>
            <Select
              name="reason"
              sx={{ margin: "10px 0px" }}
              labelId="select-label"
              variant="outlined"
              id="simple-select"
              value={selectedShareOption}
              onChange={(e) => setSelectedShareOption(e.target.value)}
              label="Share"
            >
              {shareList.map((share) => (
                <MenuItem key={share} value={share}>
                  {share}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedShareOption === "SMS" ||
          selectedShareOption === "Whatsapp" ? (
            <div className="share-doc-sms-option">
              <div className="share-textfield">
                <TextField
                  name="phone"
                  label="Phone"
                  type="text"
                  autoComplete="off"
                  value={phone}
                  disabled
                  variant="outlined"
                />
              </div>
              <TextField
                id="outlined-multiline-flexible"
                label="Message"
                multiline
                maxRows={3}
                disabled
                value={message}
              />
              <div className="share-textfield">
                <TextField
                  name="url"
                  label="URL"
                  type="text"
                  autoComplete="off"
                  value={generateURL()}
                  disabled
                  variant="outlined"
                />
              </div>
            </div>
          ) : null}
          {selectedShareOption === "Email" ? (
            <div className="share-doc-sms-option">
              <div className="share-textfield">
                <TextField
                  name="client"
                  label="Client"
                  type="text"
                  autoComplete="off"
                  value={client}
                  variant="outlined"
                  disabled
                />
              </div>
              <div className="share-textfield">
                <TextField
                  name="Email"
                  label="Email"
                  type="email"
                  autoComplete="off"
                  value={email}
                  variant="outlined"
                  disabled
                />
              </div>
            </div>
          ) : null}
        </DialogContent>
        <DialogActions>
          <div className="popup_submit_btn">
            <ButtonUI
              formButton={false}
              variant="contained"
              buttonText="Submit"
              autoFocus={true}
              onClickFunc={shareDocument}
            />
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ShareDocumentPopup;
