const shareDocuments = async ({ token, caseId, opportunityId }) => {
  try {
    const response = await fetch(
      `https://lvdmsup.nablasol.net/DMS/api/get_data_from_leads?caseId=${caseId}&opportunityId=${opportunityId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default shareDocuments;
