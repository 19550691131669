const saveRequestedDocument = async ({ token, tags }) => {
  try {
    const response = await fetch(
      "https://lvdmsup.nablasol.net/DMS/api/save_required_document",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(tags),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default saveRequestedDocument;
