import React, { useMemo, useContext } from "react";
import { format } from "date-fns";
import { useTable } from "react-table";
import { Columns } from "./Column";
import DeletePopup from "./DeletePopup";
import CopyLinkPopup from "./CopyLinkPopup";
import ShareDocumentPopup from "./ShareDocumentPopup";
import "../../../styles/Table.css";
import DocumentViewer from "./DocumentViewer";

const AllDocumentsTable = ({
  setDocumentDeletedToTrue,
  oppId,
  documentResponse,
  preferredLanguage,
  changeDocumentViewerState,
}) => {
  const columns = useMemo(() => Columns, []);
  const data = useMemo(() => documentResponse, [documentResponse]);

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        Header: "Action",
        Cell: ({ row }) => (
          <>
            <CopyLinkPopup tableValues={row.values} />
            <ShareDocumentPopup
              tableValues={row.values}
              oppId={oppId}
              preferredLanguage={preferredLanguage}
            />
            <DocumentViewer
              tableValues={row.values}
              changeDocumentViewerState={changeDocumentViewerState}
            />
            <DeletePopup
              tableValues={row.values}
              setDocumentDeletedToTrue={setDocumentDeletedToTrue}
            />
          </>
        ),
      },
    ]);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: columns,
        data: data,
        initialState: {
          hiddenColumns: [
            "DocumentID",
            "versionid",
            "Platform",
            "DocumentType",
            "download_url",
            "docUrl",
          ],
        },
      },
      tableHooks
    );

  return (
    <div className="table-content">
      <table {...getTableProps()} className="documents-table all-doc-tables">
        <thead className="documents-table-header all-doc-tables-header">
          {headerGroups.map((headerGroup, index) => {
            return (
              <tr
                key={index}
                {...headerGroup.getHeaderGroupProps()}
                className="documents-table-header-row"
              >
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    {...column.getHeaderProps()}
                    className="documents-table-header-data"
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            );
          })}
        </thead>
        <tbody
          className="documents-table-body all-doc-tables-body"
          {...getTableBodyProps()}
        >
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <tr
                key={index}
                {...row.getRowProps()}
                className="document-table-body-row"
              >
                {row.cells.map((cell, index) => {
                  switch (cell.column.Header) {
                    case "Uploaded":
                      return (
                        <td key={index}>
                          {format(new Date(cell.value), "MM-dd-yyyy")}
                        </td>
                      );
                    default:
                      return (
                        <td key={index} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AllDocumentsTable;
