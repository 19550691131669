import React from "react";
import ReactDOM from "react-dom/client";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { AuthContextProvider } from "./context/Auth/AuthContext";
import { DigitalSignatureContextProvider } from "./context/DigitalSignature/DigitalSignatureContext";
import App from "./App";
import "../src/styles/index.css";

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MsalProvider instance={msalInstance}>
    <AuthContextProvider>
      <DigitalSignatureContextProvider>
        <App />
      </DigitalSignatureContextProvider>
    </AuthContextProvider>
  </MsalProvider>
);
