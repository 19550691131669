import React, { useMemo, useContext, useState } from "react";
import { format } from "date-fns";
import { BsFillCloudArrowUpFill, BsPatchCheckFill } from "react-icons/bs";
import { TbCircleCheck } from "react-icons/tb";
import { TiDeleteOutline } from "react-icons/ti";
import { useTable, useRowSelect } from "react-table";
import { AuthContext } from "../../../context/Auth/AuthContext";
import { Columns } from "./Column";
import CheckBox from "./CheckBox";
import Dropdown from "./Dropdown";
import DeletePopup from "./DeletePopup";
import LinkPopup from "./LinkPopup";
import CategoryCell from "./CategoryCell";
import ApproveCasePopup from "./ApproveCasePopup";
import RejectCasePopup from "./RejectCasePopup";
import "../../../styles/Table.css";

const RequestedDocumentsTable = ({
  caseNo,
  caseID,
  setCategoryUpdatedToTrue,
  documentResponse,
}) => {
  const columns = useMemo(() => Columns, []);
  const data = useMemo(() => documentResponse, [documentResponse]);

  const { token } = useContext(AuthContext);

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        Header: "Action",
        Cell: ({ row }) => (
          <>
            <ApproveCasePopup
              tableValues={row.values}
              setCategoryUpdatedToTrue={setCategoryUpdatedToTrue}
            />
            <RejectCasePopup
              tableValues={row.values}
              setCategoryUpdatedToTrue={setCategoryUpdatedToTrue}
            />
            <LinkPopup
              tableValues={row.values}
              caseNo={caseNo}
              caseID={caseID}
              setCategoryUpdatedToTrue={setCategoryUpdatedToTrue}
            />
            <DeletePopup
              tableValues={row.values}
              setCategoryUpdatedToTrue={setCategoryUpdatedToTrue}
            />
          </>
        ),
      },
    ]);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    getToggleAllRowsSelectedProps,
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: {
        hiddenColumns: ["doc_id", "download_link_c"],
      },
    },
    tableHooks,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Cell: ({ row }) => (
              <>
                <CheckBox {...row.getToggleRowSelectedProps()} />
              </>
            ),
          },
          ...columns,
        ];
      });
    }
  );

  const selectedFlatRowsID = selectedFlatRows.map((row) => row.values);

  const SelectionHeader = ({ getToggleAllRowsSelectedProps }) => {
    return (
      <div className="bulk-func">
        <CheckBox {...getToggleAllRowsSelectedProps()} />
        <Dropdown
          caseNo={caseNo}
          caseID={caseID}
          selectedRows={selectedFlatRowsID}
          setCategoryUpdatedToTrue={setCategoryUpdatedToTrue}
        />
      </div>
    );
  };

  return (
    <div className="table-content">
      <table {...getTableProps()} className="documents-table case-doc-tables">
        <thead className="documents-table-header case-doc-tables-header">
          {headerGroups.map((headerGroup, index) => {
            return (
              <tr
                key={index}
                {...headerGroup.getHeaderGroupProps()}
                className="documents-table-header-row"
              >
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    {...column.getHeaderProps()}
                    className="documents-table-header-data"
                  >
                    {column.id == "selection" ? (
                      <SelectionHeader
                        getToggleAllRowsSelectedProps={
                          getToggleAllRowsSelectedProps
                        }
                      />
                    ) : (
                      column.render("Header")
                    )}
                  </th>
                ))}
              </tr>
            );
          })}
        </thead>
        <tbody
          className="documents-table-body case-doc-tables-body"
          {...getTableBodyProps()}
        >
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <tr
                key={index}
                {...row.getRowProps()}
                className="document-table-body-row"
              >
                {row.cells.map((cell, index) => {
                  switch (cell.column.Header) {
                    case "Status":
                      return (
                        <td key={index} {...cell.getCellProps()}>
                          {cell.value === "Requested" ? (
                            <span className="request-doc-request-icon request-doc-icon">
                              <BsFillCloudArrowUpFill />
                              <span class="tooltiptext">Requested</span>
                            </span>
                          ) : cell.value === "Uploaded" ? (
                            <span className="request-doc-upload-icon request-doc-icon">
                              <BsFillCloudArrowUpFill />
                              <span class="tooltiptext">Uploaded</span>
                            </span>
                          ) : cell.value === "Approved" ? (
                            <span className="request-doc-approved-icon request-doc-icon">
                              <TbCircleCheck />
                              <span class="tooltiptext">Approved</span>
                            </span>
                          ) : cell.value === "Reject" ? (
                            <span className="request-doc-rejected-icon request-doc-icon">
                              <TiDeleteOutline />
                              <span class="tooltiptext">Rejected</span>
                            </span>
                          ) : null}
                        </td>
                      );
                    case "Category":
                      return (
                        <td key={index} {...cell.getCellProps()}>
                          <CategoryCell
                            value={cell.value}
                            docID={cell.row.values.doc_id}
                            setCategoryUpdatedToTrue={setCategoryUpdatedToTrue}
                          />
                        </td>
                      );
                    case "Name":
                      return (
                        <td key={index} {...cell.getCellProps()}>
                          {cell.value === null
                            ? `${caseNo} - ${cell.row.values.doc_id}`
                            : cell.value}
                        </td>
                      );
                    case "Date":
                      return (
                        <td key={index}>
                          {format(new Date(cell.value), "MM-dd-yyyy")}
                        </td>
                      );
                    default:
                      return (
                        <td key={index} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default RequestedDocumentsTable;
