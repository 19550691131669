const sendEmail = async ({ token, selectedShareOption, sendData }) => {
  try {
    const response = await fetch(
      `https://lvdmsup.nablasol.net/DMS/api/send_mail_database_entry?type=${selectedShareOption}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(sendData),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default sendEmail;
